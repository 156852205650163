import { ApiRawModel, RawModelPreview } from '@/types/rawModel';
import { ApiRapidModel, GetRapidModelResponse, RapidModel } from '@/types/rapidModel';
import { getRpdInfo } from '@/app/assets/actions/utils';
import { getPbrRawModelDownloads } from './rawModel';
import { getImpersonation } from '@/utils/getImpersonation';
import { CloudApiError } from '@/services/errors/cloudApiError';
import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { AxiosError } from 'axios';

export const deleteRapidModels = async function (ids: number[]) {
  await cloudApiClient.post(`rapidmodel/delete`, { ids });
};

export const getRapidModels = async (rawModel: ApiRawModel): Promise<RapidModel[]> => {
  const { isImpersonating, email } = await getImpersonation();

  const endpointUrl = `rawmodel/${rawModel.id}/rapidmodels${isImpersonating ? `?impersonatedEmail=${encodeURIComponent(email)}` : ''}`;
  const { data: rapidModels } = await cloudApiClient.get<GetRapidModelResponse>(endpointUrl);

  if (rawModel.format.toLocaleLowerCase().includes('max')) {
    const rapids = await Promise.all(rapidModels.data.map(mapRapidModel));
    const downloads = await getPbrRawModelDownloads(rawModel.id);

    return [
      // we show raw model data due to current API limitations
      {
        assetType: 'output',
        ...rawModel,
        rpdInfo: { polygons: 0 },
        optimisationStatus: 'done',
        export_name: 'max',
        preset_name: 'DCC Importer',
        model: {
          fileName: rawModel.name,
          rootUrl: 'max',
        },
        downloads,
      } as RapidModel,
      ...rapids,
    ];
  }

  return Promise.all(rapidModels.data.map(mapRapidModel));
};

export const mapRapidModel = async (rapidModel: ApiRapidModel): Promise<RapidModel> => {
  return {
    assetType: 'output',
    ...rapidModel,
    rpdInfo: await getRpdInfo({ rpd_info: rapidModel.rpd_info }),
    optimisationStatus: rapidModel.optimization_status,
  };
};

export const addTagsToRapidModel = async (id: number, tags: string[]) => {
  await cloudApiClient.put(`rapidmodel/${id}`, { tags });
};

export const deleteRapidModelTag = async (id: number, tagId: number) => {
  await cloudApiClient.delete(`rapidmodel/${id}/tags/${tagId}`);
};

export type qcReportFormatResponse = {
  qc_url: string;
};

export const getQCReportByFormat = async (id: number, format: string) => {
  try {
    const { data: qcData } = await cloudApiClient.get<qcReportFormatResponse>(`rapidmodel/${id}/${format}/downloadQc`);
    console.log(qcData);
    return qcData;
  } catch (_) {
    throw new Error('Failed to get QC report');
  }
};

export const getQCReportImages = async function (id: number): Promise<Record<string, string> | null> {
  try {
    const response = await cloudApiClient.get<Record<string, string>>(`rapidmodel/${id}/renders`);
    return response.data;
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status == 404) {
      return null;
    }
    throw e;
  }
};
