import {
  ANALYSING,
  ApiMetadata,
  ApiRawModel,
  COMPLETE,
  DATAPREPPING,
  DATAPREPPING_FAILED,
  IMPORT_FAILED_LIMIT_EXCEEDED,
  IMPORTING_FAILED,
  INCOMPLETE,
  RAPID_QC,
  RawModelUploadStatus,
  RpdInfo,
  UNZIPPING,
  WAITING,
} from '@/types/rawModel';
import { ApiRapidModel } from '@/types/rapidModel';

export async function getRpdInfo({
  downloads,
  rpd_info,
}: {
  downloads?: ApiRawModel['downloads'];
  rpd_info?: ApiRapidModel['rpd_info'];
}): Promise<RpdInfo> {
  const rpdUrl = downloads?.['rpd_info.json'] ?? rpd_info;
  if (rpdUrl) {
    try {
      const rpdInfoResponse = await fetch(rpdUrl, { cache: 'force-cache' });
      const rpdInfo = (await rpdInfoResponse.json()) as RpdInfo;

      const polygons: number | null =
        rpdInfo && typeof rpdInfo.numTriangles === 'number' && typeof rpdInfo.numQuads === 'number'
          ? rpdInfo.numTriangles + rpdInfo.numQuads
          : null;

      return { ...rpdInfo, polygons };
    } catch (e) {
      console.error('An error occurred while fetching the info file', e);
    }
  }

  return { polygons: null };
}

export async function getFileContentsAsList(fileUrl: string | undefined): Promise<string[]> {
  if (!fileUrl) {
    return [];
  }

  const response = await fetch(fileUrl);
  const data = await response.text();

  return data
    .trim()
    .split('\n')
    .filter((s) => s != '');
}

export const rawModelPendingStates = new Set<RawModelUploadStatus>([
  ANALYSING,
  DATAPREPPING,
  UNZIPPING,
  RAPID_QC,
  WAITING,
]);

export const rawModelFinishedStates = new Set<RawModelUploadStatus>([
  COMPLETE,
  INCOMPLETE,
  DATAPREPPING_FAILED,
  IMPORTING_FAILED,
  IMPORT_FAILED_LIMIT_EXCEEDED,
]);

export const rawModelSuccessStates = new Set<RawModelUploadStatus>([COMPLETE]);

export const rawModelErrorStates = new Set<RawModelUploadStatus>([
  INCOMPLETE,
  DATAPREPPING_FAILED,
  IMPORTING_FAILED,
  IMPORT_FAILED_LIMIT_EXCEEDED,
]);

export const getMetadata = (metadata: ApiMetadata | undefined) => {
  try {
    return metadata
      ? {
          createdAt: metadata.created_at,
          updatedAt: metadata.updated_at,
          id: metadata.id,
          data: JSON.parse(metadata.data),
        }
      : undefined;
  } catch (error) {
    return undefined;
  }
};
