import { cloudApiClient } from '@/services/clientSideCloudApiService';

export interface RpdxVersions {
  current?: string;
  legacy?: string;
}

export const getRPDXVersion = async function () {
  const result = await cloudApiClient.get('rpdx');
  return { current: result.data[0], legacy: result.data[1] } as RpdxVersions;
};

export const getRapidPBRVersion = async function () {
  const result = await cloudApiClient.get('rapidpbr/version');
  return result.data.rapidpbr_version as string;
};

export const getAppVersion = async function () {
  return (process.env.NEXT_PUBLIC_VERSION_TAG ?? 'dev') as string;
};
