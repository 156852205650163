export function getSearchQuery(searchTerm?: string, page?: number) {
  if (!searchTerm && !page) {
    return '';
  }

  let query = '?';
  let isFirst = true;

  const append = (q: string) => {
    isFirst ? (query += q) : (query += `&${q}`);
    isFirst = false;
  };

  if (searchTerm) {
    const searchLabels = [{ id: Date.now(), value: searchTerm }];
    append(`q=${encodeURIComponent(JSON.stringify(searchLabels))}`);
  }

  if (page) {
    append(`page=${page}`);
  }

  return query;
}
