import { Modal, Button, Text, Divider, Group } from '@mantine/core';
import { ReactNode } from 'react';

export interface ConfirmationModalProps {
  opened: boolean;
  onConfirm: () => void;
  close: () => void;
  content: ReactNode;
  title: ReactNode;
  action: string;
}

export function ConfirmationModal({ opened, onConfirm, content, action, close, title }: ConfirmationModalProps) {
  return (
    <Modal
      opened={opened}
      title={<Text fw={600}>{title}</Text>}
      onClose={close}
      classNames={{
        root: 'h-full',
        content: 'flex flex-col',
        body: 'flex flex-col h-full overflow-hidden pb-0',
        header: 'py-0',
      }}
      zIndex={1000}
    >
      <Text mb='md'>{content}</Text>
      <Divider mx={-24} />
      <Group gap='xs' justify='flex-end' p='sm'>
        <Button onClick={close} variant='default'>
          <Text>Cancel</Text>
        </Button>
        <Button onClick={onConfirm}>
          <Text>{action}</Text>
        </Button>
      </Group>
    </Modal>
  );
}
