import { clearCookies } from '@/app/proxy-server-cookie';
import { signOut } from 'next-auth/react';
import { Crisp } from 'crisp-sdk-web';

export const customSignOut = async () => {
  await clearCookies();
  await signOut({ redirect: true });
  Crisp.setTokenId();
  Crisp.session.reset();
};
